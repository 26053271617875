import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { CustomLink } from './custom-link'
import Button from '@material-ui/core/Button'
import { IconButtonCtaArrow } from './icons'
import Img from 'gatsby-image'

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.blue.light100
  },
  wrapper: () => ({
    padding: '60px 0 68px',
    backgroundSize: 'cover',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 0 40px',
      '& h3': {
        padding: '0 24px',
        marginBottom: '24px'
      },
      '& h1': {
        marginBottom: '5px'
      }
    }
  })
}))

const Features = ({ images }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper} display="flex" flexDirection="column">
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Box style={{ height: '100px' }}>
                  <Img fixed={images.FeaturedImage1.childImageSharp.fixed} />
                </Box>
                <Typography variant="h5" color="secondary" gutterBottom>
                  How Nike is approaching coaching
                </Typography>
                <Button
                  variant="text"
                  color="secondary"
                  endIcon={<IconButtonCtaArrow />}
                  component={CustomLink}
                  href="https://howtocoachkids.org/"
                >
                  Learn More
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Box style={{ height: '100px' }}>
                  <Img fixed={images.FeaturedImage2.childImageSharp.fixed} />
                </Box>
                <Typography variant="h5" color="secondary" gutterBottom>
                  Join the changing the game movement
                </Typography>
                <Button
                  variant="text"
                  color="secondary"
                  endIcon={<IconButtonCtaArrow />}
                  component={CustomLink}
                  href="https://changingthegameproject.com/"
                >
                  Learn More
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Box style={{ height: '100px' }}>
                  <Img fixed={images.FeaturedImage3.childImageSharp.fixed} />
                </Box>
                <Typography variant="h5" color="secondary" gutterBottom>
                  Check out the PGA.coach Parent Module
                </Typography>
                <Button
                  variant="text"
                  color="secondary"
                  endIcon={<IconButtonCtaArrow />}
                  component={CustomLink}
                  href="https://vt.lightspeedvt.com/trainingCenter/course/66021?returnUrl=%2F"
                >
                  Learn More
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Box style={{ height: '100px' }}>
                  <Img fixed={images.FeaturedImage4.childImageSharp.fixed} />
                </Box>
                <Typography variant="h5" color="secondary" gutterBottom>
                  Review the ADM Golf Parent Checklist
                </Typography>
                <Button
                  variant="text"
                  color="secondary"
                  endIcon={<IconButtonCtaArrow />}
                  component={CustomLink}
                  href="https://lsvt-courseware.s3.amazonaws.com/files/664/76B0A03BE56BC8AB67A11906E90B7E4F/ADM%20Golf%20Parent-PGA.pdf"
                >
                  Learn More
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default Features

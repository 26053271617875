import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'
import { IconArrow } from './icons'
import Slider from 'react-slick'

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: '60px 0',
    maxWidth: '100%',
    overflow: 'hidden'
  },
  wrapper: () => ({
    padding: '32px 0 68px',
    backgroundSize: 'cover',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 0 40px',
      '& h3': {
        padding: '0 24px',
        marginBottom: '24px'
      },
      '& h1': {
        marginBottom: '5px'
      }
    }
  }),
  button: {
    width: '3.0625rem',
    height: '3.0625rem',
    display: 'flex',
    padding: 0,
    minWidth: 'initial',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    color: '#3268A7',
    position: 'absolute',
    bottom: '-80px',
    left: '50%',
    border: 'none',
    zIndex: '100',
    '&:hover': {
      color: '#F7F7F7',
      backgroundColor: '#001529'
    },
    '&:focus': {
      border: '3px solid white',
      outline: '3px solid black'
    }
  },
  prevButton: {
    transform: 'rotate(180deg) translateX(100%)',
    backgroundColor: '#F7F7F7',
    left: '50%'
  }
}))

export const SliderItem = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    right: 10px;
    width: 100%;
    height: 100%;
    @media (max-width: 992px) {
      left: 0px;
      right: 0px;
    }
  }
`

export const SliderWrapper = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 0 auto 90px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    display: block;
    width: 240px;
    height: 100%;
    background-image: linear-gradient(
      90deg,
      #ffffff 0%,
      #ffffff 11%,
      rgba(255, 255, 255, 0.81) 55%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  &:after {
    right: 0px;
    transform: rotate(180deg);
  }
  &:before {
  }
  @media (max-width: 992px) {
    width: 90%;
    margin: 0 auto 90px;
    &:before,
    &:after {
      width: 80px;
    }
    .slick-list {
      padding: 0 !important;
    }
  }
`

const PrevButton = props => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <button
      className={`${classes.button} ${classes.prevButton}`}
      onClick={onClick}
    >
      <IconArrow />
    </button>
  )
}

const NextButton = props => {
  const { onClick } = props
  const classes = useStyles()
  return (
    <button className={classes.button} onClick={onClick}>
      <IconArrow />
    </button>
  )
}

const Video = ({ src, title }) => (
  <iframe
    allowFullScreen
    frameBorder="0"
    width="640"
    height="360"
    src={src}
    title="PGA Coaching Platform, hosted by Bill Cowher"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
  />
)

const Videos = () => {
  const classes = useStyles()
  const sliderSettings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '240px',
    slidesToShow: 1,
    speed: 500,
    dots: false,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  }
  return (
    <Box className={classes.root}>
      <Container maxWidth="xl" style={{ overflow: 'hidden' }}>
        <SliderWrapper>
          <Slider {...sliderSettings}>
            <SliderItem>
              <Video
                src="https://www.youtube.com/embed/BmOlzRQTabA"
                title="Five More Years"
              />
            </SliderItem>
            <SliderItem>
              <Video
                src="https://www.youtube.com/embed/-0e8zvvY-x8"
                title="Car Ride Home"
              />
            </SliderItem>
            <SliderItem>
              <Video
                src="https://www.youtube.com/embed/ReKw6J5tK2c"
                title="It’s Just a Game"
              />
            </SliderItem>
            <SliderItem>
              <Video
                src="https://www.youtube.com/embed/TInFe337gZQ"
                title="9-year-old announces retirement from sports | ESPN"
              />
            </SliderItem>
          </Slider>
        </SliderWrapper>
      </Container>
    </Box>
  )
}

export default Videos

import React from 'react'
import { LayoutV2 } from '../components/common'
import Hero from '../components/hero-image'
import { graphql } from 'gatsby'
import { JssProvider } from 'react-jss'
import StagesSet from '../components/common/stages-set'
import { createGenerateClassName } from '@material-ui/core/styles'
import Features from '../components/features'
import Videos from '../components/videos'

const CoachParentsPage = props => {
  const classPrefix = 'index-new-page'
  const { heroImage, heroImageMobile } = props.data
  const generateClassName = createGenerateClassName({
    productionPrefix: classPrefix
  })
  return (
    <JssProvider generateClassName={generateClassName}>
      <LayoutV2 title="PGA.Coach New">
        <Hero
          image={heroImage}
          mobileImage={heroImageMobile}
          title="Coaching Parents"
          description="See below for resources to share with parents of your students"
        />
        <StagesSet images={props.data} />
        <Features images={props.data} />
        <Videos />
      </LayoutV2>
    </JssProvider>
  )
}

export const query = graphql`
  query SearchQuery2 {
    StageImg1: file(relativePath: { eq: "Stages/StageImg1.jpg" }) {
      childImageSharp {
        fixed(width: 185, height: 155) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    StageImg2: file(relativePath: { eq: "Stages/StageImg2.jpg" }) {
      childImageSharp {
        fixed(width: 185, height: 155) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    StageImg3: file(relativePath: { eq: "Stages/StageImg3.jpg" }) {
      childImageSharp {
        fixed(width: 185, height: 155) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    StageImg4: file(relativePath: { eq: "Stages/StageImg4.jpg" }) {
      childImageSharp {
        fixed(width: 185, height: 155) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    StageImg5: file(relativePath: { eq: "Stages/StageImg5.jpg" }) {
      childImageSharp {
        fixed(width: 185, height: 155) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    StageImg6: file(relativePath: { eq: "Stages/StageImg6.jpg" }) {
      childImageSharp {
        fixed(width: 185, height: 155) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    StageImg7: file(relativePath: { eq: "Stages/StageImg7Large.jpg" }) {
      childImageSharp {
        fixed(width: 185, height: 335) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    featuredImg: file(relativePath: { eq: "Stages/StageImgExpanded7.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroImage: file(relativePath: { eq: "coach-parents-hero.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroImageMobile: file(
      relativePath: { eq: "coach-parents-hero-mobile.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    FeaturedImage1: file(
      relativePath: { eq: "CoachParents/HTCK_FullColour.png" }
    ) {
      childImageSharp {
        fixed(width: 99, height: 89) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    FeaturedImage2: file(
      relativePath: { eq: "CoachParents/ctg_logo_main-1024x572.png" }
    ) {
      childImageSharp {
        fixed(width: 118, height: 56) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    FeaturedImage3: file(
      relativePath: { eq: "CoachParents/PGA-PARENT-MODULE.png" }
    ) {
      childImageSharp {
        fixed(width: 100, height: 66) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    FeaturedImage4: file(
      relativePath: { eq: "CoachParents/PGA-ADM-GOLF-PARENT.png" }
    ) {
      childImageSharp {
        fixed(width: 92, height: 57) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default CoachParentsPage
